$blue: #9edbf7;
$black: #000;
$green: #406b7f;
$light-green: #abc7a5;
$yellow: #f4e7b3;
$light-green: #abc7a5;
$background-quiz-page: #fdf3da;
$extras-green: #9ab993;
$white: white;
$max-width: 1060px;
$base-font-size: 16px;
$grey: #5f5f5f; 
$orange: #d56f4f;
$dark-orange: #952d2f;
$primary-color: $orange;
$secondary-color: $green;
$light-yellow: #fdf3da;
$dark-orange: #952d2f;
$body-font-color: $black;
$section-border-top: $primary-color;
$animation-time: 0.3s;

/* Header */
$header-background-color: $blue;
$header-background-image: url("https://supadu-io.imgix.net/harpercollins-littlehousebooks/littlehousebooks-site/header-image.jpg?auto=format") no-repeat center $blue;
//NAVIGATION
$nav-links-colour: $white;
$nav-links-colour-hover: #ffc4b2;
$nav-current-link-colour: $white;
$nav-background-colour: #e96e48;
// $nav-hamburger-colour: $white;

/* Characters */
$blue-character: #7db3ad;
$yellow-character: #f4e9a8;
$green-character: #96a74e;
$pink-character: #eba490;
$red-character: #e06859;

/* default font sizes */
$base-font-size: 16px;
$h1-font-size: 40px;
$h2-font-size: 40px;
$h3-font-size: 20px;
$h4-font-size: 20px;
$h5-font-size: 16px;
