.extras-list {
    ul {
      li {
        margin-bottom: rem(10px);
        @extend %primary-font;
        font-size: rem(18px);
  
        a {
          color: $orange;
          &:hover {
            color: $dark-orange;
          }
        }
      }
    }
  }  
