.supafolio-loader-container {
    .supafolio-loader {
        border-top-color: $secondary-color;
    }
}
.book-detail {
    .book-content {
        &__top {
            padding-top: 24px;
            @include breakpoint(md) {
                padding-top: 64px;
            }
            background: $yellow;
            @include full_width_colour($yellow);
        }
    }
    h1 {
        color: $secondary-color;
        margin-top: 30px;
        @include breakpoint(md) {
            font-size: 54px;
            line-height: 1;
            margin-top: 0;
        }
    }
    h3,
    h3 + p {
        @extend %main-font;
        font-size: 20px;
    }
    .flextabs-container {
        background: $yellow;
        @include full_width_colour($yellow);
    }
    .product-tabs {
        width: 100%;
        padding-top: 10px;
        @include breakpoint(md) {
            padding-top: 0;
        }
        &:before,
        &:after {
            z-index: 1;
        }
        .flextabs__tab {
            button {
                text-transform: uppercase;
                color: $secondary-color;
            }
            @include breakpoint(md) {
                .flextabs__toggle--active--last {
                    border-color: $primary-color $primary-color #fff;
                }
            }
        }
        @include breakpoint(md) {
            .flextabs__content:after,
            .flextabs__content:before,
            .flextabs__content--active--last {
                border-top-color: $primary-color;
            }
        }
    }
    .buy-button-wrapper {
        background: $light-yellow;
        .format {
            font-size: 30px;
            @extend %secondary-font;
        }
        .price {
            font-size: 24px;
            font-weight: bold;
        }
        .buy-button {
            text-transform: uppercase;
            padding: 16px 20px;
        }
    }
    .button-asset.rounded-full {
        background-color: $secondary-color;
        padding: 0;
        a {
            border-radius: 9999px;
            display: block;
            color: $white;
            padding: 15px 30px;
        }
        &:hover {
            a {
                background-color: $white;
                color: $secondary-color;
            }
        }
    }
    .other-formats {
        > p:first-child {
            @extend %secondary-font;
            font-size: 20px;
        }
        ul {
            li {
                a {
                    color: $secondary-color;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}
.related-books {
    &-title {
        h1 {
            border-top: 2px solid $light-yellow;
            font-size: 30px;
            color: $primary-color;
            padding-top: 24px;
        }
    }
}
