.header {
  background-color: $header-background-color;
  @include breakpoint(md) {
    background: $header-background-image;
    background-size: cover;
  }
  &__logo {
    padding-top: 0 !important;
    > a:first-child {
      padding: 70px 0 10px 0;
      max-width: 270px;
      @include breakpoint(md) {
        padding: 15px 0 20px 0;
      }
    }
    @include breakpoint(md) {
      > a:first-child {
        display: block;
        position: relative;
        left: -220px;
      }
    }
  }
  .main-menu {
    li {
      @include breakpoint(md) {
        margin-right: 2rem;
      }
    }
  }
}
