.recipes-wrapper {
  border-radius: 10px;
  &:before,
  &:after {
    display: none;
  }
  .recipe-details {
    ol,
    ul {
      padding-left: 17px;
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: disc;
    }
  }
}
