%primary-font {
  font-family: "Source Serif Pro", serif;
  font-weight: 400;
}

%secondary-font {
  font-family: "Source Serif Pro", serif;
  font-weight: 300;
  text-transform: none;
}

%main-font {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: $base-font-size;
  line-height: $base-font-size + 5;
  text-transform: none;
}

.yellow-background {
  background-color: $yellow;
  @include full_width_colour($yellow);
}

.blue-background {
  background-color: $blue;
  @include full_width_colour($blue);
}

.light-yellow-background {
  background-color: $light-yellow;
  @include full_width_colour($light-yellow);
}

.primary-background {
  background-color: $light-green;
  @include full_width_colour($light-green);
}

h1,
.primary-font {
  @extend %primary-font;
}

.primary-text-colour {
  color: $primary-color;
}

h2,
h3,
.secondary-font {
  @extend %secondary-font;
}

.secondary-text-colour {
  color: $secondary-color;
}

.third-text-colour {
  color: $dark-orange;
}

.extras-green-colour {
  color: $extras-green;
}

h4,
h5,
body,
.main-font {
  @extend %main-font;
}

.main-text-colour {
  color: $body-font-color;
}

h1 {
  font-size: $h1-font-size;
  line-height: $h1-font-size + 5;
  margin-bottom: 20px;
}

h2 {
  font-size: $h2-font-size;
  line-height: $h2-font-size;
  margin-bottom: 10px;
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-font-size;
  margin-bottom: 10px;
}

h4 {
  font-size: $h4-font-size;
  line-height: $h4-font-size + 5;
  margin-bottom: 10px;
}

h5 {
  font-size: $h5-font-size;
  line-height: $h5-font-size + 5;
  margin-bottom: 10px;
}

blockquote {
  font-size: 30px;
  line-height: 30px;
  @extend %secondary-font;
  font-weight: 400;
  cite {
    font-style: normal;
    text-transform: none;
  }
}

.separator:before {
  content: "";
  height: 70px;
  background: url("https://supadu-io.imgix.net/harpercollins-littlehousebooks/littlehousebooks-site/separator.png?auto=format") center;
  width: 300px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  margin: 0 auto;
}

.pagination {
  li {
    a {
      color: $orange;
    }
  }
}

.pagination-wrapper {
  > p {
    color: $orange;
  }
}

a {
  color: $body-font-color;
  &:hover {
    color: $primary-color;
  }
}

p {
  margin-bottom: 1em;
}

.primary-button {
  @extend %primary-font;
  @include siteButton($primary-color, $white);
}

.format-button.primary-button {
  border: 2px solid $primary-color;
  color: $primary-color;
  &.active {
    color: $white;
  }
  &:hover,
  &.active {
    background-color: $primary-color;
    &:after {
      border-top-color: $primary-color;
    }
  }
}

.buy-button.primary-button {
  background-color: $orange;
  &:hover {
    background-color: $orange;
    color: $white;
  }
}

.secondary-button {
  @extend %secondary-font;
  @include siteButton($secondary-color, $white);
  &__character {
    display: inline-block;
    text-align: center;
  }
}

.white-button {
  @extend %secondary-font;
  @include siteButton($white, $secondary-color);
}

.button-asset--audio {
  @include siteButton($white, $black);
  &:hover {
    background-color: $white;
    color: $black;
  }
}

.back-button {
  margin: 20px 0;
  a {
    &:hover {
      color: $black;
    }
  }
}
