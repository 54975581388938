.related-books,
.books-carousel {
    .tns-controls {
        button {
            position: relative;
        }
        [data-controls="prev"] {
            left: -20px;
        }
        [data-controls="next"] {
            right: -20px;
        }
    }
}
