.books-nav {
  &__item {
    @include sliderLinks($orange);
    .component__link {
      a {
        font-size: 20px;
        line-height: normal;
      }
    }
  }
}
