.quiz {
  &-list {
    ul {
      li {
        margin-bottom: rem(10px);
        a {
          color: $dark-orange;
          &:hover {
            color: $orange;
          }
        }
      }
    }
  }
  &-wrapper {
    background: $orange;
    .form__input-index {
      background-color: $dark-orange;
      @extend %secondary-font;
      font-size: 28px;
      line-height: 28px;
    }
    button[type="submit"] {
      @extend %secondary-font;
      @include siteButton($green, $white);
      border-radius: 0;
      min-width: 150px;
    }
  }
}
