.character-nav {
  &__item {
    @include sliderLinks($green);
    padding: 0 20px;
    .component > div > a {
      border-radius: 50%;
      display: block;
      border: 8px solid transparent;
      transition: $animation-time all ease;
    }
    .component__link a {
      font-size: 20px;
      color: $black;
    }
    &:hover {
      .component > div > a {
        border: 8px solid;
      }
      .component__link a {
        color: $black;
      }
      &.blue {
        .component > div > a {
          @include border-opacity($blue-character, 0.5);
        }
        .component__link a {
          border-color: $blue-character;
        }
      }
      &.yellow {
        .component > div > a {
          @include border-opacity($yellow-character, 0.5);
        }
        .component__link a {
          border-color: $yellow-character;
        }
      }
      &.green {
        .component > div > a {
          @include border-opacity($green-character, 0.5);
        }
        .component__link a {
          border-color: $green-character;
        }
      }
      &.pink {
        .component > div > a {
          @include border-opacity($pink-character, 0.5);
        }
        .component__link a {
          border-color: $pink-character;
        }
      }
      &.red {
        .component > div > a {
          @include border-opacity($red-character, 0.5);
        }
        .component__link a {
          border-color: $red-character;
        }
      }
    }
  }
}
