/*
	Breakpoints
*/
@mixin breakpoint($point) {
    @if $point == md {
        @media (min-width: rem(768px)) {
            @content;
        }
    }
}
